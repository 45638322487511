import React from "react";

import ContactsContent from "./ContactsContent";

const ContactsPage = () => {
  return (
    <>
      <div className="regular-slider">
        <div className="display-table center-text">
          <div className="display-table-cell">
            <div className="slider-content">
              <h1 className="title">Kontakt</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="regular-area">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="content">
                <ContactsContent />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactsPage;
