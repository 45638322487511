import React from "react";

import { ContactsHTML } from "./ContactsHTML";
import { CoordinatorHTML } from "./CoordinatorHTML";

const template = { __html: ContactsHTML };
const ContactsWrapper = () => {
  return <div dangerouslySetInnerHTML={template} />;
};

const coordinator = { __html: CoordinatorHTML };
const CoordinatorWrapper = () => {
  return <div dangerouslySetInnerHTML={coordinator} className="mt-3" />;
};

const ContactsContent = () => {
  return (
    <>
      <CoordinatorWrapper />
      <ContactsWrapper />

      <div className="pt-lg-5 pt-xl-5">
        <h2 className="margin-top-btm pt-lg-5 pt-xl-5">Kde je ten mlejn?</h2>
        <iframe
          title="Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2548.096703616257!2d14.59568035180856!3d50.30878740534182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470be430d25d5311%3A0x99f6682a76c558a4!2zTW9kcsO9IG1sw71u!5e0!3m2!1scs!2scz!4v1580569471957!5m2!1scs!2scz"
          width="100%"
          height="300"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen={true}
        ></iframe>
      </div>
    </>
  );
};

export default ContactsContent;
