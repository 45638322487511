export const AccomodationHTML = `
<table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
  <tr>
    <td align="center" valign="top">
      <table width="100%" border="0" cellspacing="0" cellpadding="0">
        <tr>
          <td align="center" class="mp0">
            <table width="650" border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
              <tr>
                <td
                  class="td"
                  style="width:650px; min-width:650px; font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;"
                >
                  <table width="100%" border="0" cellspacing="0" cellpadding="0" dir="rtl" style="direction: rtl;">
                    <tr>
                      <th
                        class="column-dir"
                        dir="rtl"
                        width="325"
                        style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal; direction:ltr;"
                      >
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                          <tr>
                            <td class="fluid-img" style="font-size:0pt; line-height:0pt; text-align:left;">
                              <img
                                src="https://wedding.tobolovi.com/newsletter/mlynice.jpg"
                                width="325"
                                border="0"
                                alt=""
                              />
                            </td>
                          </tr>
                        </table>
                      </th>
                      <th
                        class="column-dir"
                        dir="rtl"
                        style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal; direction:ltr;"
                      >
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                          <tr>
                            <td class="p30-15" style="padding: 50px 50px 50px 0px;">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td
                                    class="h5 white"
                                    style="font-family:'Roboto Slab', Arial, sans-serif; font-size:20px; line-height:24px; text-align:left; color:#66575c"
                                  >
                                    <multiline>V PROSTORÁCH</multiline>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="h4 white pb20"
                                    style="font-family:'Roboto Slab', Arial, sans-serif; font-size:35px; line-height:40px; text-align:left; text-transform:uppercase; font-weight:300; color:#66575c; padding-bottom:20px;"
                                  >
                                    <multiline>MLÝNICE</multiline>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="text2 pb30"
                                    style="color:#66575c; font-family:'Roboto Slab', Arial, sans-serif; font-size:16px; line-height:34px; text-align:left; font-weight:300; padding-bottom:30px;"
                                  >
                                    <multiline>
                                      V mlýně se nachází prostorná společná mlýnice, kam se vejde cca 10 spáčů.
                                      V této variantě spacáky a karimatku ideálně s&nbsp;sebou.
                                    </multiline>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </th>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4">
        <tr>
          <td align="center">
            <table width="650" border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
              <tr>
                <td
                  class="td"
                  style="width:650px; min-width:650px; font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;"
                >
                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr>
                      <th
                        class="column"
                        width="325"
                        style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;"
                      >
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                          <tr>
                            <td class="fluid-img" style="font-size:0pt; line-height:0pt; text-align:left;">
                              <img
                                src="https://wedding.tobolovi.com/newsletter/garden.jpg"
                                width="325"
                                border="0"
                                alt=""
                              />
                            </td>
                          </tr>
                        </table>
                      </th>
                      <th
                        class="column"
                        style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;"
                      >
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                          <tr>
                            <td class="p30-15" style="padding: 50px 0px 50px 50px;">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td
                                    class="h5 white"
                                    style="font-family:'Roboto Slab', Arial, sans-serif; font-size:20px; line-height:24px; text-align:left; color:#66575c"
                                  >
                                    <multiline>NA ZAHRADĚ</multiline>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="h4 white pb20"
                                    style="font-family:'Roboto Slab', Arial, sans-serif; font-size:35px; line-height:40px; text-align:left; text-transform:uppercase; font-weight:300; color:#66575c; padding-bottom:20px;"
                                  >
                                    <multiline>VE STANU</multiline>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="text2 pb30"
                                    style="color:#66575c; font-family:'Roboto Slab', Arial, sans-serif; font-size:16px; line-height:34px; text-align:left; font-weight:300; padding-bottom:30px;"
                                  >
                                    <multiline>
                                      V prostorách velké zahrady se dá ve večerních hodinách postavit stan
                                      a&nbsp;přespat v něm.
                                    </multiline>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </th>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <table width="100%" border="0" cellspacing="0" cellpadding="0">
        <tr>
          <td align="center" class="mp0">
            <table width="650" border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
              <tr>
                <td
                  class="td"
                  style="width:650px; min-width:650px; font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;"
                >
                  <table width="100%" border="0" cellspacing="0" cellpadding="0" dir="rtl" style="direction: rtl;">
                    <tr>
                      <th
                        class="column-dir"
                        dir="rtl"
                        width="325"
                        style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal; direction:ltr;"
                      >
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                          <tr>
                            <td class="fluid-img" style="font-size:0pt; line-height:0pt; text-align:left;">
                              <img
                                src="https://wedding.tobolovi.com/newsletter/apartments.jpg"
                                width="325"
                                border="0"
                                alt=""
                              />
                            </td>
                          </tr>
                        </table>
                      </th>
                      <th
                        class="column-dir"
                        dir="rtl"
                        style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal; direction:ltr;"
                      >
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                          <tr>
                            <td class="p30-15" style="padding: 50px 50px 50px 0px;">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td
                                    class="h5 white"
                                    style="font-family:'Roboto Slab', Arial, sans-serif; font-size:20px; line-height:24px; text-align:left; color:#66575c"
                                  >
                                    <multiline>APARTMÁNY</multiline>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="h4 white pb20"
                                    style="font-family:'Roboto Slab', Arial, sans-serif; font-size:35px; line-height:40px; text-align:left; text-transform:uppercase; font-weight:300; color:#66575c; padding-bottom:20px;"
                                  >
                                    <multiline>V PODZÁMČÍ</multiline>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="text2 pb30"
                                    style="color:#66575c; font-family:'Roboto Slab', Arial, sans-serif; font-size:16px; line-height:34px; text-align:left; font-weight:300; padding-bottom:30px;"
                                  >
                                    <multiline>
                                      Mělník nabízí ubytování v krásných a prostorných apartmánech za slušnou cenu.
                                    </multiline>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="text-link3"
                                    style="color:#66575c; font-family:'Roboto Slab', Arial, sans-serif; font-size:16px; line-height:20px; text-align:left; font-weight:bold;"
                                  >
                                    <multiline
                                    ><a
                                      href="https://www.apartmanymelnik.cz/index.php"
                                      target="_blank"
                                      class="link4"
                                      style="color:#66575c; text-decoration:none;"
                                    ><span class="link4" style="color:#66575c; text-decoration:none;"
                                    ><span class="yellow" style="color:#ffbaba;">&mdash;</span
                                    >&nbsp;VÍCE</span
                                    ></a
                                    ></multiline
                                    >
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </th>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4">
        <tr>
          <td align="center">
            <table width="650" border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
              <tr>
                <td
                  class="td"
                  style="width:650px; min-width:650px; font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;"
                >
                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr>
                      <th
                        class="column"
                        width="325"
                        style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;"
                      >
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                          <tr>
                            <td class="fluid-img" style="font-size:0pt; line-height:0pt; text-align:left;">
                              <img
                                src="https://wedding.tobolovi.com/newsletter/zamek.jpg"
                                width="325"
                                border="0"
                                alt=""
                              />
                            </td>
                          </tr>
                        </table>
                      </th>
                      <th
                        class="column"
                        style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;"
                      >
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                          <tr>
                            <td class="p30-15" style="padding: 50px 0px 50px 50px;">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td
                                    class="h5 white"
                                    style="font-family:'Roboto Slab', Arial, sans-serif; font-size:20px; line-height:24px; text-align:left; color:#66575c"
                                  >
                                    <multiline>NA ZÁMKU</multiline>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="h4 white pb20"
                                    style="font-family:'Roboto Slab', Arial, sans-serif; font-size:35px; line-height:40px; text-align:left; text-transform:uppercase; font-weight:300; color:#66575c; padding-bottom:20px;"
                                  >
                                    <multiline>LIBLICE</multiline>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="text2 pb30"
                                    style="color:#66575c; font-family:'Roboto Slab', Arial, sans-serif; font-size:16px; line-height:34px; text-align:left; font-weight:300; padding-bottom:30px;"
                                  >
                                    <multiline>
                                      Kdo se bude chtít ubytovat na zámku, je domluvená 25% sleva. Doporučené je
                                      ubytování v zámeckých křídlech. 15 minut pěšky od mlýna, minutu autem.
                                    </multiline>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="text-link3"
                                    style="color:#66575c; font-family:'Roboto Slab', Arial, sans-serif; font-size:16px; line-height:20px; text-align:left; font-weight:bold;"
                                  >
                                    <multiline
                                    ><a
                                      href="http://www.zamek-liblice.cz"
                                      target="_blank"
                                      class="link4"
                                      style="color:#66575c; text-decoration:none;"
                                    ><span class="link4" style="color:#66575c; text-decoration:none;"
                                    ><span class="yellow" style="color:#ffbaba;">&mdash;</span
                                    >&nbsp;VÍCE</span
                                    ></a
                                    ></multiline
                                    >
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </th>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
        <tr>
          <td class="acc-columns" align="center" style="padding-bottom: 70px; padding-top: 50px;">
            <table width="650" border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
              <tr>
                <td
                  class="td"
                  style="width:650px; min-width:650px; font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;"
                >
                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr>
                      <th
                        class="column"
                        width="200"
                        style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;"
                      >
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                          <tr>
                            <td class="p30-15">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td
                                    class="fluid-img pb20"
                                    style="font-size:0pt; line-height:0pt; text-align:left; padding-bottom:20px;"
                                  ></td>
                                </tr>
                                <tr>
                                  <td
                                    class="h5 pb15"
                                    style="color:#333333; font-family:'Roboto Slab', Arial, sans-serif; font-size:20px; line-height:24px; text-align:left; padding-bottom:15px;"
                                  >
                                    <multiline>HOTEL BOR</multiline>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="text4 pb15"
                                    style="color:#66575c; font-family:'Roboto Slab', Arial, sans-serif; font-size:15px; line-height:28px; text-align:left; padding-bottom:15px;"
                                  >
                                    <multiline
                                    >Hotel situovaný v&nbsp;přírodě u&nbsp;lesa, naleznete v&nbsp;obci Lhotka ...
                                    </multiline>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="text-link"
                                    style="color:#333333; font-family:'Roboto Slab', Arial, sans-serif; font-size:16px; line-height:20px; text-align:left; font-weight:bold;"
                                  >
                                    <multiline
                                    ><a
                                      href="http://www.hotelborlhota.com/"
                                      target="_blank"
                                      class="link-black"
                                      style="color:#66575c; text-decoration:none;"
                                    ><span class="link-black" style="color:#66575c; text-decoration:none;"
                                    ><span class="yellow" style="color:#ffbaba;">&mdash;</span
                                    >&nbsp;VÍCE</span
                                    ></a
                                    ></multiline
                                    >
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </th>
                      <th class="m-td" width="25" style="font-size:0pt; line-height:0pt; text-align:left;"></th>
                      <th
                        class="column"
                        width="200"
                        style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;"
                      >
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                          <tr>
                            <td class="p30-15">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td
                                    class="fluid-img pb20"
                                    style="font-size:0pt; line-height:0pt; text-align:left; padding-bottom:20px;"
                                  ></td>
                                </tr>
                                <tr>
                                  <td
                                    class="h5 pb15"
                                    style="color:#333333; font-family:'Roboto Slab', Arial, sans-serif; font-size:20px; line-height:24px; text-align:left; padding-bottom:15px;"
                                  >
                                    <multiline>HOTEL SWAMI</multiline>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="text4 pb15"
                                    style="color:#66575c; font-family:'Roboto Slab', Arial, sans-serif; font-size:15px; line-height:28px; text-align:left; padding-bottom:15px;"
                                  >
                                    <multiline
                                    >Komfortní hotel má pro vás připravené ubytování v&nbsp;širším centru Mělníka
                                      ...
                                    </multiline>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="text-link"
                                    style="color:#333333; font-family:'Roboto Slab', Arial, sans-serif; font-size:16px; line-height:20px; text-align:left; font-weight:bold;"
                                  >
                                    <multiline
                                    ><a
                                      href="https://hotel-swami.hotel.cz/?gclid=Cj0KCQiAvc_xBRCYARIsAC5QT9k7V1rJW4R0lnxEyFZ2pjMfRSRlb7T3sOKDSLSXAkQq6xIonJuOBwIaAqY-EALw_wcB"
                                      target="_blank"
                                      class="link-black"
                                      style="color:#66575c; text-decoration:none;"
                                    ><span class="link-black" style="color:#66575c; text-decoration:none;"
                                    ><span class="yellow" style="color:#ffbaba;">&mdash;</span
                                    >&nbsp;VÍCE</span
                                    ></a
                                    ></multiline
                                    >
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </th>
                      <th class="m-td" width="25" style="font-size:0pt; line-height:0pt; text-align:left;"></th>
                      <th
                        class="column"
                        width="200"
                        style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;"
                      >
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                          <tr>
                            <td class="p30-15">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td
                                    class="fluid-img pb20"
                                    style="font-size:0pt; line-height:0pt; text-align:left; padding-bottom:20px;"
                                  ></td>
                                </tr>
                                <tr>
                                  <td
                                    class="h5 pb15"
                                    style="color:#333333; font-family:'Roboto Slab', Arial, sans-serif; font-size:20px; line-height:24px; text-align:left; padding-bottom:15px;"
                                  >
                                    <multiline>HOTEL U RYTÍŘE</multiline>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="text4 pb15"
                                    style="color:#66575c; font-family:'Roboto Slab', Arial, sans-serif; font-size:15px; line-height:28px; text-align:left; padding-bottom:15px;"
                                  >
                                    <multiline
                                    >Odkazem na dobu raného středověku budiž Hotel u&nbsp;Rytíře ...
                                    </multiline>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="text-link"
                                    style="color:#333333; font-family:'Roboto Slab', Arial, sans-serif; font-size:16px; line-height:20px; text-align:left; font-weight:bold;"
                                  >
                                    <multiline
                                    ><a
                                      href="http://www.hotelurytire.cz/index.html"
                                      target="_blank"
                                      class="link-black"
                                      style="color:#66575c; text-decoration:none;"
                                    ><span class="link-black" style="color:#66575c; text-decoration:none;"
                                    ><span class="yellow" style="color:#ffbaba;">&mdash;</span
                                    >&nbsp;VÍCE</span
                                    ></a
                                    ></multiline
                                    >
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </th>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>
</body>
</html>
`;
