import React from "react";
// @ts-ignore
import { useTimer } from "react-timer-hook";

import config from "../../constants";

interface TimerResult {
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
  start: () => void;
  pause: () => void;
  resume: () => void;
  restart: (newExpiryTimestamp: number) => void;
}

const Countdown: React.FC<{ timer: TimerResult }> = props => (
  <div className="row">
    <div className="col-sm-12">
      <div className="heading">
        <h2 className="title">Svatba začíná za</h2>
        <span className="heading-bottom">
          <i className="color-white icon icon-star" />
        </span>
      </div>
    </div>
    <div className="col-sm-2" />
    <div className="col-sm-8">
      <div className="remaining-time">
        <div id="clock">
          <div className="time-sec">
            <span className="title">{props.timer.days}</span> dnů
          </div>
          <div className="time-sec">
            <span className="title">{props.timer.hours}</span> hodin
          </div>
          <div className="time-sec">
            <span className="title">{props.timer.minutes}</span> minut
          </div>
          <div className="time-sec">
            <span className="title">{props.timer.seconds}</span> sekund
          </div>
        </div>
      </div>
    </div>
  </div>
);

const WeddingOver = () => (
  <div className="row">
    <div className="col-sm-12">
      <div className="heading mb-n5">
        <h2 className="title">Svatba se odehrála 10.7.2021</h2>
        <span className="heading-bottom">
          <i className="color-white icon icon-star" />
        </span>
      </div>
    </div>
  </div>
);

const CountdownSection = () => {
  const timer: TimerResult = useTimer({
    expiryTimestamp: config.WEDDING_DATE
  });

  return (
    <section className="section counter-area center-text">
      <div className="container">
        {Date.now() > config.WEDDING_DATE ? <WeddingOver /> : <Countdown timer={timer} />}
      </div>
    </section>
  );
};

export default CountdownSection;
