import React from "react";

import GuestInfoContent from "./GuestInfoContent";

const GuestInfoPage = () => {
  return (
    <>
      <div className="regular-slider">
        <div className="display-table center-text">
          <div className="display-table-cell">
            <div className="slider-content">
              <h1 className="title">Informace pro hosty</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="regular-area">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="content">
                <GuestInfoContent />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GuestInfoPage;
