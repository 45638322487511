export const CoordinatorHTML = `
<table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
  <tr>
    <td align="center" valign="top">
      <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
        <tr>
          <td align="center">
            <table width="650" border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
              <tr>
                <td
                  class="td"
                  style="width:650px; min-width:650px; font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;"
                >
                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr>
                      <th
                        class="column"
                        width="325"
                        style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;"
                      >
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                          <tr>
                            <td class="fluid-img" style="font-size:0pt; line-height:0pt; text-align:left;">
                              <img
                                src="https://wedding.tobolovi.com/contact/koordinatorka-klara.jpg"
                                width="325"
                                border="0"
                                alt=""
                              />
                            </td>
                          </tr>
                        </table>
                      </th>
                      <th
                        class="column"
                        style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;"
                      >
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                          <tr>
                            <td class="p30-15" style="padding: 50px 0px 15px 50px;">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td
                                    class="h5 white"
                                    style="font-family:'Roboto Slab', Arial, sans-serif; font-size:20px; line-height:24px; text-align:left; color:#66575c"
                                  >
                                    <multiline>KOORDINÁTORKA</multiline>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="h4 white pb20"
                                    style="font-family:'Roboto Slab', Arial, sans-serif; font-size:35px; line-height:40px; text-align:left; text-transform:uppercase; font-weight:300; color:#66575c; padding-bottom:20px;"
                                  >
                                    <multiline>KLÁRA</multiline>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="text2 pb30"
                                    style="color:#66575c; font-family:'Roboto Slab', Arial, sans-serif; font-size:16px; line-height:34px; text-align:left; font-weight:300; padding-bottom:30px;"
                                  >
                                    <multiline>
                                      Pokud budete mít ve svatební den jakékoliv dotazy, obraťte se na naši sympatickou koordinátorku Kláru.
                                      Miluje smysl pro detail, je plná nápadů a pozitivní energie.
                                    </multiline>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </th>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>
</body>
</html>
`;
