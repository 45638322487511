import "../assets/css/styles.css";
import "../assets/css/responsive.css";

import React, { MouseEvent, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

import Overlay from "../components/Overlay";
import ScrollToTop from "../components/ScrollToTop";
import ContactsPage from "../views/contacts/ContactsPage";
import GuestInfoPage from "../views/guest-info/GuestInfoPage";
import LandingPage from "../views/landing/LandingPage";

const PageLayout: React.FC = (content: any) => {
  const [isMenuOpened, setMenuOpened] = useState(false);
  const mainMenuClassNames = () => {
    if (isMenuOpened) return "main-menu visible main-menu-end";
    else return "main-menu main-menu-start";
  };
  const toggleMenu = (e?: MouseEvent) => {
    if (e != null) e.preventDefault();
    setMenuOpened(!isMenuOpened);
  };
  const closeMenuIfOpened = () => {
    if (isMenuOpened) setMenuOpened(false);
  };

  return (
    <Router>
      <header>
        <Overlay active={isMenuOpened}>
          <div className="container menu-container">
            <OutsideClickHandler
              disabled={!isMenuOpened}
              onOutsideClick={() => {
                closeMenuIfOpened();
              }}
            >
              <Link to="/" onClick={closeMenuIfOpened} className="logo">
                <h3 className="menu-logo">Svatba Tobolovi</h3>
              </Link>
              <div className="menu-nav-icon" data-nav-menu="#main-menu">
                <i className="icon icon-bars" onClick={toggleMenu} />
              </div>
              <ul className={mainMenuClassNames()} id="main-menu">
                <li>
                  <Link to="/" onClick={closeMenuIfOpened}>
                    ÚVODNÍ STRÁNKA
                  </Link>
                </li>
                <li>
                  <Link to="/guests" onClick={closeMenuIfOpened}>
                    INFORMACE PRO HOSTY
                  </Link>
                </li>
                <li>
                  <Link to="/contact" onClick={closeMenuIfOpened}>
                    KONTAKT
                  </Link>
                </li>
              </ul>
            </OutsideClickHandler>
          </div>
        </Overlay>
      </header>

      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/guests" component={GuestInfoPage} />
          <Route exact path="/contact" component={ContactsPage} />
          <Redirect to="/" />
        </Switch>
      </ScrollToTop>

      <footer>
        <div className="container center-text">
          <ul className="footer-links">
            <li>
              <Link to="/">ÚVODNÍ STRÁNKA</Link>
            </li>
            <li>
              <Link to="/guests">INFORMACE PRO HOSTY</Link>
            </li>
            <li>
              <Link to="/#contact">KONTAKT</Link>
            </li>
          </ul>
          <p className="copyright small">
            Copyright &copy; All rights reserved | This template is made with{" "}
            <i className="icon-heart" aria-hidden="true" /> by{" "}
            <a href="https://colorlib.com" target="_blank" rel="noopener noreferrer">
              Colorlib
            </a>{" "}
            | modified by{" "}
            <a href="http://jantobola.com" target="_blank" rel="noopener noreferrer">
              Jan Tobola
            </a>
          </p>
        </div>
      </footer>
    </Router>
  );
};

export default PageLayout;
