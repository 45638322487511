import React from "react";

import { ReactComponent as Heels } from "../../assets/icons/010-high heels.svg";
import { ReactComponent as Cake } from "../../assets/icons/013-wedding cake.svg";
import { ReactComponent as Photo } from "../../assets/icons/014-photo.svg";
import { ReactComponent as Car } from "../../assets/icons/025-car.svg";
import { ReactComponent as Rings } from "../../assets/icons/026-wedding rings.svg";
import { ReactComponent as Wives } from "../../assets/icons/030-wives.svg";
import { ReactComponent as Fun } from "../../assets/icons/confetti.svg";
import { ReactComponent as Flower } from "../../assets/icons/flower-bouquet.svg";
import { ReactComponent as Grill } from "../../assets/icons/grill.svg";
import { ReactComponent as Music } from "../../assets/icons/music.svg";
import { ReactComponent as Rest } from "../../assets/icons/rest.svg";
import { ReactComponent as Lunch } from "../../assets/icons/romantic-dinner.svg";
import { ReactComponent as Wine } from "../../assets/icons/wine-bottle.svg";
import config from "../../constants";

const Agenda = () => {
  return (
    <div className="wedding-details margin-bottom">
      <div className="w-detail left">
        <Car width={50} height={50} fill="#ffbaba" />
        <h4 className="title">11:30 - PŘÍJEZD</h4>
        {/*<p>Look at the most passionate ceremony ever. We wish happiness to the wedding couple.</p>*/}
      </div>
      <div className="w-detail right">
        <Rings width={50} height={50} fill="#ffbaba" />
        <h4 className="title">13:00 - OBŘAD</h4>
        {/*<p>Look at the most passionate ceremony ever. We wish happiness to the wedding couple.</p>*/}
      </div>
      <div className="w-detail left">
        <Photo width={50} height={50} fill="#ffbaba" />
        <h4 className="title">SPOLEČNÉ FOCENÍ</h4>
        {/*<p>Look at the most passionate ceremony ever. We wish happiness to the wedding couple.</p>*/}
      </div>
      <div className="w-detail right">
        <Lunch width={50} height={50} fill="#ffbaba" stroke="#ffbaba" strokeWidth={12} />
        <h4 className="title">DLABANEC</h4>
        {/*<p>Look at the most passionate ceremony ever. We wish happiness to the wedding couple.</p>*/}
      </div>
      <div className="w-detail left">
        <Wives width={50} height={50} fill="#ffbaba" />
        <h4 className="title">FOCENÍ NOVOMANŽELŮ</h4>
        {/*<p>Look at the most passionate ceremony ever. We wish happiness to the wedding couple.</p>*/}
      </div>
      <div className="w-detail right">
        <Fun width={50} height={50} fill="#ffbaba" stroke="#ffbaba" strokeWidth={9} />
        <h4 className="title">SRANDY KOPEC</h4>
        {/*<p>Look at the most passionate ceremony ever. We wish happiness to the wedding couple.</p>*/}
      </div>
      <div className="w-detail left">
        <Cake width={50} height={50} fill="#ffbaba" />
        <h4 className="title">KRÁJENÍ DORTU</h4>
        {/*<p>Look at the most passionate ceremony ever. We wish happiness to the wedding couple.</p>*/}
      </div>
      <div className="w-detail right">
        <Flower width={50} height={50} fill="#ffbaba" stroke="#ffbaba" strokeWidth={8} />
        <h4 className="title">KYTICE A PODVAZEK</h4>
        {/*<p>Look at the most passionate ceremony ever. We wish happiness to the wedding couple.</p>*/}
      </div>
      <div className="w-detail left">
        <Music width={50} height={50} fill="#ffbaba" stroke="#ffbaba" strokeWidth={11} />
        <h4 className="title">NOVOMANŽELSKÝ TANEC</h4>
        {/*<p>Look at the most passionate ceremony ever. We wish happiness to the wedding couple.</p>*/}
      </div>
      <div className="w-detail right">
        <Grill width={50} height={50} fill="#ffbaba" stroke="#ffbaba" strokeWidth={11} />
        <h4 className="title">BARBECUE</h4>
        {/*<p>Look at the most passionate ceremony ever. We wish happiness to the wedding couple.</p>*/}
      </div>
      <div className="w-detail left">
        <Heels width={50} height={50} fill="#ffbaba" />
        <h4 className="title">SVATEBNÍ KVÍZ</h4>
        {/*<p>Look at the most passionate ceremony ever. We wish happiness to the wedding couple.</p>*/}
      </div>
      <div className="w-detail right">
        <Wine width={50} height={50} fill="#ffbaba" stroke="#ffbaba" strokeWidth={9} />
        <h4 className="title">VEČERNÍ ZÁBAVA</h4>
        {/*<p>Look at the most passionate ceremony ever. We wish happiness to the wedding couple.</p>*/}
      </div>
      <div className="w-detail left">
        <Rest width={50} height={50} fill="#ffbaba" stroke="#ffbaba" strokeWidth={8} />
        <h4 className="title">Bž?? SPáá_^&% ChrRrrRrrrr.....</h4>
        {/*<p>Look at the most passionate ceremony ever. We wish happiness to the wedding couple.</p>*/}
      </div>
    </div>
  );
};

const AgendaTBD = () => {
  return <p>Bude upřesněno později.</p>;
};

const AgendaSection = () => {
  return (
    <section className="section w-details-area center-text">
      <div className="container">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-10">
            <div className="heading">
              <h2 className="title" id="harmonogram">
                Orientační harmonogram
              </h2>
              <span className="heading-bottom-dark">
                <i className="icon icon-star" />
              </span>
            </div>
            {config.PUBLISH_AGENDA ? <Agenda /> : <AgendaTBD />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AgendaSection;
