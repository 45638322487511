import React from "react";
import { Card, CardDeck } from "react-bootstrap";
import styled from "styled-components";

import { CardBorderless } from "../../components/Styled";
import { AccomodationHTML } from "./AccomodationHTML";

const template = { __html: AccomodationHTML };
const AccomodationWrapper = () => {
  return <div dangerouslySetInnerHTML={template} className="mt-5" />;
};

interface IColorBlock {
  color: string;
}

const ColorBlock = styled.div`
  width: 100%;
  height: 240px;
  background-color: ${(p: IColorBlock) => p.color};
`;

const ColorBlockHeader = styled(Card.Title)`
  text-align: center;
  font-size: 1.05em;
  color: gray;
`;

const GuestInfoContent = () => {
  return (
    <>
      <h2 className="margin-top-btm mt-5">Příjezd svatebčanů</h2>
      <p>
        Příjezd svatebčanů je naplánován od 11:30 hodin. Pokud vaše ubytování nebude poskytovat non-stop recepci, bylo
        by fajn domluvit si dřívější check-in, abyste se po příchodu na mlýn mohli už jen bavit, slavit a v pozdních
        hodinách mít vše připravené k zalehnutí.
      </p>

      <h2 className="margin-top-btm mt-5">Svatební dary</h2>
      <p className="margin-top-btm">
        Dovolte nám v tuto chvíli malou prosbičku, namísto věcných darů naplňte naši kasičku. Vděčni vám budem dosti,
        poděkujem za každý halířek, je lepší ten, než nosit domů další kastrol či talířek.
      </p>

      <h2 className="margin-top-btm mt-5">Dresscode</h2>
      <p className="margin-top-btm">
        Netrváme na striktně formálních pravidlech oblékání. Naopak. Přiměřeně ležérní styl nebude na škodu.
      </p>
      <p className="margin-top-btm">
        Dámy, vyhněte se však, prosím, celobarevným šatům v bílé, černé, nebo červené. Všechny pány prosíme, aby na
        obřad měli košili a dlouhé kalhoty. Pokud nás schvátí velká vedra, sako můžete nechat opodál.
      </p>
      <p className="margin-top-btm">
        Myslete, prosím, na to, že celý den se nese ve slavnostním duchu, přizpůsobte tomu proto své oblečení i v
        odpoledních hodinách.
      </p>
      <p className="margin-top-btm">
        Na svatbě se můžete setkat s těmito barvami. Pokud budete mít chuť, můžete s nimi sladit svůj outfit.
      </p>

      <CardDeck>
        <CardBorderless>
          <ColorBlock color="#ffc5a1" />
          <Card.Body>
            <ColorBlockHeader>Lehčí Meruňková</ColorBlockHeader>
            <Card.Text></Card.Text>
          </Card.Body>
        </CardBorderless>
        <CardBorderless>
          <ColorBlock color="#b0deff" />
          <Card.Body>
            <ColorBlockHeader>Světlejší Modrá</ColorBlockHeader>
            <Card.Text></Card.Text>
          </Card.Body>
        </CardBorderless>
        <CardBorderless>
          <ColorBlock color="#ffbaba" />
          <Card.Body>
            <ColorBlockHeader>Jemnější růžová</ColorBlockHeader>
            <Card.Text></Card.Text>
          </Card.Body>
        </CardBorderless>
      </CardDeck>

      <h2 className="margin-top-btm mt-5">Trhači</h2>
      <p className="margin-top-btm">
        Společně s námi budou slavit i dva pejsci. Prosíme, nedávejte jim dobroty i kdyby na vás dělali ty nejsmutnější
        psí oči, které jste kdy viděli.
      </p>

      <h2 className="margin-top-btm">Ubytování</h2>

      <p className="margin-top-btm">
        Jelikož chceme, abyste si s&nbsp;námi užili tento den naplno a&nbsp;měli dostatek času rezervovat si ubytování,
        zde je pár tipů. Pokud s&nbsp;námi nezůstanete na mlýně a&nbsp;ubytujete se v&nbsp;okolí, jsou pro všechny
        domluvené rozvozy ze mlýna v&nbsp;okruhu&nbsp;<i>+-20&nbsp;km</i>. Odjezdy ze mlýna před půlnocí jsou pod
        hrozbou pokuty!
      </p>
      <p className="margin-top-btm">
        Mlýn se nachází kousek od Mělníka. <i>42&nbsp;km</i> od Prahy,
        <i> 121&nbsp;km</i> od Hradce Králové a&nbsp;<i>134&nbsp;km</i> od Tábora. Jelikož si dobrá a&nbsp;dlooouhá
        oslava žádá nocleh, zde je několik možností ubytování.
      </p>

      <AccomodationWrapper />
    </>
  );
};

export default GuestInfoContent;
