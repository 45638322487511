import React from "react";
import { Link } from "react-router-dom";

const GuestInfoSection = () => {
  return (
    <section className="section ceremony-area center-text">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="heading">
              <h2 className="title">Detailní informace pro hosty</h2>
              <span className="heading-bottom">
                <i className="color-white icon icon-star" />
              </span>
            </div>
            <div className="ceremony">
              <p className="desc margin-bottom">
                V této sekci naleznete mnoho užitečných informací, např. pár tipů na ubytování v okolí a jaké jsme si
                vybrali svatební barvy. Průběžně budeme tuto sekci rozšiřovat a doplňovat o další informace.
              </p>
              <Link to="/guests" className="border-btn">
                VÍCE
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GuestInfoSection;
