import React from "react";

interface OverlayProps {
  active?: boolean;
}

const Overlay: React.FC<OverlayProps> = props => {
  return <div className={props.active ? "overlay" : ""}>{props.children}</div>;
};

Overlay.defaultProps = {
  active: false
};

export default Overlay;
