import React from "react";

const AboutWeddingSection = () => {
  return (
    <section className="section story-area center-text">
      <div className="container">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-10">
            <div className="heading">
              <h2 className="title">Pár slov o svatbě</h2>
              <span className="heading-bottom-dark">
                <i className="icon icon-star" />
              </span>
            </div>
            <p className="desc">
              Vítejte na venkovské svatbě, která bude probíhat v prostorách starého mlýna. Obřad, hostinu i&nbsp;párty
              si užijeme právě tam. Mlýn je veliký a&nbsp;je rozdělen na několik částí. Budete si moct užít prosluněnou
              zahradu s&nbsp;drinkem, whisky salónek (na pozvání od žěnicha), fotokoutek a&nbsp;mnoho dalšího...
            </p>

            <div className="heading quoto-area margin-top-btm mt-5">
              <h3 className="quoto">“Jdi s tím, kdo tě má rád&nbsp;”</h3>
              <span style={{ color: "#555555" }}>- od Huga🤦‍♂️😆</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutWeddingSection;
