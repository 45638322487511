import React from "react";

import AboutWeddingSection from "./AboutWeddingSection";
import AgendaSection from "./AgendaSection";
import ContactSection from "./ContactSection";
import CountdownSection from "./CountdownSection";
import GuestInfoSection from "./GuestInfoSection";
import MainHeader from "./MainHeader";

const LandingPage: React.FC = () => {
  return (
    <>
      <MainHeader />
      <AboutWeddingSection />
      <CountdownSection />
      <AgendaSection />
      <GuestInfoSection />
      <ContactSection />
    </>
  );
};

export default LandingPage;
