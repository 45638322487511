import React from "react";

const MainHeader = () => {
  return (
    <div className="main-slider">
      <div className="display-table center-text">
        <div className="display-table-cell">
          <div className="slider-content">
            <h3 className="pre-title date">10. 7. 2021</h3>
            <i className="small-icon icon icon-tie" />
            <h3 className="pre-title">Svatba v Modrém mlýně</h3>
            <h1 className="title">
              Markéta <i className="icon icon-heart" /> Honza
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
