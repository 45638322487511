import React from "react";
import { Link } from "react-router-dom";

const ContactSection = () => {
  return (
    <section className="section center-text">
      <div className="container">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-10">
            <div className="heading">
              <h2 className="title">Kontakt</h2>
              <span className="heading-bottom-dark">
                <i className="icon icon-star" />
              </span>
            </div>
            <div>
              <p className="desc margin-bottom">V případě jakýchkoliv dotazů se obraťte na nás nebo naše svědky.</p>
              <Link to="/contact" className="border-btn">
                VÍCE
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
